import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Banner extends Component {
    constructor(props){
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Comprometidos con la transformación integral',
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Liderazgo innovador en el servicio público',
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Profundidad en Políticas de Desarrollo Comunitario y Económico',
                },
            ]
        }
    }
    render() {
        return (
            <section className="banner pb-80">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-6">
                            <div className="banner-content ">
                                <span className="subheading">Transformando APAN con Visión y Compromiso</span>
                                <h1>María Gloria Hernández Madrid </h1>
                                <ul>
                                    {
                                    this.state.listItem.map((data,i) => (
                                        <li key={i}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>                              </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-img">
                                <img src="assets/images/banner/book-2.png" alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div> 
                </div> 
            </section>

        );
    }
}

export default Banner;

