import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import links from '../links';
import menus from '../menus';

class Header extends Component {
    

    render() {
        return (

            <header>
                <div className="site-navigation " id="mainmenu-area">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container">
                            <Link to="https://gloriahernandezmadridapan.com" className="navbar-brand">
                                <img src="assets/images/logomp.png"     style={{ width: '120px', height: 'auto' }}  className="img-fluid"/>
                            </Link>

                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="fa fa-bars"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarMenu">                                
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item dropdown">
                                        <Link to="#" className="nav-link dropdown-toggle"  
                                            id="navbar2" role="button" data-toggle="dropdown" aria-haspopup="true" 
                                            aria-expanded="false">Inicio
                                        </Link>
                                    </li>

                                    {
                                        menus.map((menu) => (
                                            <li className="nav-item" key={menu.id}><HashLink to={menu.tomenu} className="nav-link js-scroll-trigger">{menu.namemenu}</HashLink></li>
                                        ))
                                    }  
                                    
                                </ul>
                            </div>


                            <div className="header-right-info d-none d-lg-block">
                                <ul className="header-socials">
                                    <li><Link to="https://www.facebook.com/GloriaHernandezMadrid"><i className="fab fa-facebook-f" aria-hidden="true" /></Link></li>
                                    <li><Link to="https://www.instagram.com/gloriahdezm/"><i className="fab fa-instagram" aria-hidden="true" /></Link></li>
                                    {/* <li><Link to="https://www.tiktok.com/@marlene.puertas?_t=8jh3VuuEPkk&_r=1"><i className="fab fa-tiktok" aria-hidden="true" /></Link></li> */}
                                </ul>
                            </div>
                        </div> 
                    </nav>
                </div>
            </header>
         

        );
    }
}

export default Header;
